import React from "react";

import SiteLayout from "@layouts/site-layout";
import HeaderEnrollNow from "@components/ExploreMore/customComponents/HeaderEnrollNow";
import AppliedLearningProject from "@components/ExploreMore/AppliedLearningProject";
import ColorfulBox from "@components/ExploreMore/customComponents/ColorfulBox";
import BuildJob from "@components/ExploreMore/customComponents/BuildJob";
import Skills from "@components/ExploreMore/customComponents/Skills";
import Certificate from "@components/ExploreMore/customComponents/Certificate";
import WhatyouWillLearn from "@components/ExploreMore/customComponents/WhatyouWillLearn";

const data = {
  boxDetails: {
    title: "Design Thinking",
    text:
      "Unleash your creative side with a scientific approach to design best-selling high impactful products",
    rating: "4.9/5",
    totalRating: "( 91,664 ratings )",
    enroll: "1,223,890 already enrolled",
    started: "Start from 15 april",
  },

  colorfulBoxContent: [
    {
      bgColor: "#FBFDD1",
      txtColor: "#7D7D7D",
      headerColor: "#545706",
      headerTxt: "3 Months",
      contentTxt: "Under 10 hours study of study a week.",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#7D7D7D",
      headerColor: "#0A662A",
      headerTxt: "English",
      contentTxt: "Under 10 hours study of study a week.",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#7D7D7D",
      headerColor: "#6F0755",
      headerTxt: "Beginner",
      contentTxt: "No prior experience required.",
    },
    {
      bgColor: "#DFD4FF",
      txtColor: "#7D7D7D",
      headerColor: "#2A0D7E",
      headerTxt: "100% Self-Paced",
      contentTxt: "No prior experience required.",
    },
  ],

  jobTitles: [
    { contentTxt: "Design thinking consultant" },
    { contentTxt: "Research & Development Executive" },
    { contentTxt: "Product Design" },
    { contentTxt: "Product Developer" },
  ],

  jobTitlesBox: [
    {
      bgColor: "#DFD4FF",
      txtColor: "#7D7D7D",
      headerColor: "#2A0D7E",
      headerTxt: "500,000",
      contentTxt: "in-demand Job Listings",
    },
    {
      bgColor: "#FBFDD1",
      txtColor: "#7D7D7D",
      headerColor: "#545706",
      headerTxt: "12 LPA+",
      contentTxt: "Median salary",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#7D7D7D",
      headerColor: "#0A662A",
      headerTxt: "100,000 TB",
      contentTxt: "of data transformed and analysed in 2022",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#7D7D7D",
      headerColor: "#6F0755",
      headerTxt: "14+",
      contentTxt: "Hands on projects.",
    },
  ],

  skills: [
    {
      bgColor: "#FCFFA6",
      txtColor: "#9B9F19",
      contentTxt: "Design thinking",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#0D8036",
      contentTxt: "Creativity",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#ED77D0",
      contentTxt: "Ergonomics",
    },
    {
      bgColor: "#E0E9FA",
      txtColor: "#4545AF",
      contentTxt: "Product strategy",
    },
  ],

  aboutProfessionalCertificate: [
    "This course is designed for individuals who want to develop the skills necessary to create innovative solutions to complex problems.",
    "Throughout the course, you will learn how to use the principles of design thinking to understand user needs, identify opportunities, and prototype and test new solutions. You will also gain a deep understanding of the design thinking process and how it can be applied to a range of industries and fields",
  ],

  whatYouWillLearn: [
    "This course is designed for individuals who want to develop the skills necessary to create innovative solutions to complex problems.",
    "Throughout the course, you will learn how to use the principles of design thinking to understand user needs, identify opportunities, and prototype and test new solutions. You will also gain a deep understanding of the design thinking process and how it can be applied to a range of industries and fields.",
    "Our experienced instructors will guide you through a range of topics, including empathy and user research, ideation and brainstorming, prototyping and testing, and iteration and refinement. You will also have the opportunity to engage in hands-on activities and projects, allowing you to apply your knowledge in a practical setting.",
    "By the end of the course, you will have developed a comprehensive understanding of the design thinking process and how to apply it to solve complex problems. You will also have a portfolio of innovative solutions that demonstrate your design thinking skills and approach.",
    "Join us on this exciting journey towards becoming a skilled design thinker, and learn how to create meaningful and impactful solutions that drive change and innovation.",
  ],

  appliedLearningProject:
    "What is an applied learning project? It's a project-based learning experience that allows students to take an active role in solving real-world problems. Through our program, students will work closely with faculty, industry experts, and community partners to identify a problem or challenge, develop a plan of action, and implement a solution.",

  benefitsForStudents: [
    {
      headerTxt: "Practical experience :",
      contentTxt:
        "Students will gain practical, hands-on experience that is directly applicable to their future careers.",
    },
    {
      headerTxt: "Problem-solving skills :",
      contentTxt:
        "Through our program, students will develop critical thinking and problem-solving skills that will help them succeed in their future careers.",
    },
    {
      headerTxt: "Networking opportunities :",
      contentTxt:
        "Our program offers students the opportunity to network with industry experts and community partners, building valuable connections for their future careers",
    },
    {
      headerTxt: "Personal growth :",
      contentTxt:
        "Through our program, students will develop their interpersonal skills, teamwork abilities, and leadership capabilities, all of which are essential for success in any career.",
    },
    {
      headerTxt: "Resume building :",
      contentTxt:
        "Participating in an applied learning project is a valuable addition to any student's resume, demonstrating their commitment to experiential learning and problem-solving.",
    },
  ],

  startLearningToday: [
    { contentTxt: "Comprehensive knowledge" },
    { contentTxt: "Hands-on experience" },
    { contentTxt: "In-demand skills" },
    { contentTxt: "Creative Freedom" },
    { contentTxt: "Flexibility" },
    { contentTxt: "Community" },
  ],
};

const index = () => {
  return (
    <SiteLayout>
      <div className="home_middle_sec top_margin_comn pt-5 align-items-center">
        <div className="container px-3 row pb-3 pb-md-5">
          <div className="col-12 col-md-6">
            <HeaderEnrollNow
              headerText={data.boxDetails.title}
              subtitle={data.boxDetails.text}
              rating={data.boxDetails.rating}
              totalRating={data.boxDetails.totalRating}
              enroll={data.boxDetails.enroll}
              started={data.boxDetails.started}
            />
          </div>
          <div className="col-12 col-md-6 header_colorfulBox_container py-3">
            {data.colorfulBoxContent.map((item) => (
              <ColorfulBox item={item} />
            ))}
          </div>
        </div>
        <BuildJob jobTitles={data.jobTitles} jobTitlesBox={data.jobTitlesBox} />
        <Skills skills={data.skills} />
        <Certificate />
        <AboutProfessionalCertificate />
        <WhatyouWillLearn whatYouWillLearn={data.whatYouWillLearn} />
        <AppliedLearningProject />
      </div>
    </SiteLayout>
  );
};

export default index;

const AboutProfessionalCertificate = () => {
  return (
    <div className="container">
      <div className="row justify-content-center py-5">
        <span className="headerText">About this Professional Certificate</span>
      </div>
      <div className="row justify-content-center pb-5">
        <p className="contentText text-center">
          This course is designed for individuals who want to develop the skills
          necessary to create{" "}
          <span className="highlight_text">
            {" "}
            innovative solutions to complex problems.
          </span>
        </p>
      </div>
      <div className="row justify-content-center pb-5">
        <p className="contentText text-center">
          Throughout the course, you will learn how to use the{" "}
          <span className="highlight_text">
            {" "}
            principles of design thinking{" "}
          </span>{" "}
          to understand{" "}
          <span className="highlight_text">
            {" "}
            user needs, identify opportunities, and prototype and test new
            solutions.{" "}
          </span>{" "}
          You will also gain a{" "}
          <span className="highlight_text"> deep understanding </span> of the{" "}
          <span className="highlight_text"> design thinking process </span> and
          how it can be applied to a range of industries and fields
        </p>
      </div>
    </div>
  );
};
